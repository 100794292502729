import { createTheme } from '@mui/material/styles';

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#9c27b0',
    },
    background: {
      paper: '#232323',
    },
    text: {
      primary: '#fff',
    },
    error: {
      main: '#ff3665',
    },
  },
});
